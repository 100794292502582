
<template>
    <DxScheduler
        :data-source="dataSource"
        :current-date="selectedDate"
        :min="minDate"
        :max="maxDate"
        :views="views"
        :editing="editing"
        :height="height"
        :width="width"
        :current-view="currentView"
        :date-cell-template="dateCellTemplate"
        :data-cell-template="dataCellTemplate"
        :time-cell-template="timeCellTemplate"
        @cell-click="onCellClick"
        @appointment-form-opening="onAppointmentFormOpening"
        @appointment-added="onAppointmentAdded"
        @appointment-updated="onAppointmentUpdated"
        @appointment-deleted="onAppointmentDeleted"
    >
        <template #dataCellTemplate="{ data: cellData }">
            <div :class="{ 'disable-date' : isDisabledDate(cellData) }">
                {{ cellData.text }}
            </div>
        </template>

        <!-- <template #dateCellTemplate="{ data: cellData }">
            <div :class="'disabled'">
                {{ cellData.text }}
            </div>
        </template>

        <template #timeCellTemplate="{ data: cellData }">
            <div :class="'wesh'">
                {{ cellData.text }}
                <div class="cafe">pzfe</div>
            </div>
        </template> -->
    </DxScheduler>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import moment from 'moment';
import { DxScheduler } from 'devextreme-vue/scheduler';
import RecurrenceEditor from "devextreme/ui/recurrence_editor";
import frMessages from 'devextreme/localization/messages/fr.json';
import { locale, loadMessages } from 'devextreme/localization';
import { rrulestr } from 'rrule';

export default{
    name: 'DateSelector',
    components: {
        DxScheduler,
    },
    props: {
        offre: {
            type: Object,
            required: true,
            id: {
                type: Number
            },
            titre: {
                type: String
            },
            duree: {
                type: Number
            },
            dates: {
                type: Object,
                id: {
                    type: Number
                },
                date: {
                    type: Date
                }
            }
        },
        saveUrl: {
            type: String
        },
        deleteUrl: {
            type: String
        },
        currentView: {
            type: String,
            default: "month"
        },
        views: {
            type: Array,
            default: () => ['month', 'week', 'day']
        },
        currentDate: {
            type: String
        },
        min: {
            type: String
        },
        max: {
            type: String
        },
        height: {
            type: Number
        },
        width: {
            type: Number
        },
        editing: {
            type: Object,
            default: () => ({
                allowAdding: true,
                allowDeleting: true,
                allowDragging: false,
                allowResizing: false,
                allowTimeZoneEditing: false,
                allowUpdating: false
            })
        },
        dateCellTemplate: {
            type: String
        },
        dataCellTemplate: {
            type: String
        },
        timeCellTemplate: {
            type: String
        }
    },
    emits: [
        'save',
        'delete'
    ],
    computed: {
        duree() {
            // 30MN PAR DEFAUT
            return parseInt(this.offre.duree) ? parseInt(this.offre.duree) : 30;
        },
        selectedDate() {
            return this.currentDate ? new Date(this.currentDate) : new Date;
        },
        minDate() {
            return this.min ? new Date(this.min) : new Date((new Date()).getTime() + 1000 * 60 * 60 * 24 * 20);
        },
        maxDate() {
            // MAX 1 AN PAR DEFAUT
            return this.max ? new Date(this.max) : new Date((new Date()).getTime() + 1000 * 60 * 60 * 24 * 365);
        },
        dataSource() {
            // INITIALISATION DES DATES EXISTANTES
            return this.offre.dates.map(dt => {
                let endDate;
                if (dt.endDate) {
                    endDate = new Date(dt.endDate.replace("+00:00", ""))
                } else {
                    endDate = new Date(new Date(dt.date.replace("+00:00", "")).getTime() + 1000 * 60 * this.duree)
                }
                return {
                    id: dt.id,
                    text: this.offre.titre,
                    startDate: new Date(dt.date.replace("+00:00", "")),
                    // DATE FIN PAR DEFAUT A DATE DEBUT +dureeMN
                    endDate: endDate
                }
            });
        }
    },
    created() {
        loadMessages(frMessages);
        locale('fr-FR');
    },
    methods: {
        isDisabledDate(cellData) {
            const date = cellData.startDate;

            if (date < this.minDate || date > this.maxDate) {
                return true;
            }
            return false;
        },
        onCellClick(e) {
            // DESACTIVATION DES DATES DANS LE PASSEE ET DE PLUS D'1 AN
            if (this.isDisabledDate(e.cellData)) {
                e.cancel = true;
            }
        },
        onAppointmentFormOpening(e) {
            var form = e.form, startDate = e.appointmentData.startDate;

            // ASSIGNATION DU TITRE DE L'EVENT (READONLY)
            form.updateData("text", this.offre.titre);

            // MODIFICATION DU FORM PRINCIPAL
            var mainGroupItems = form.itemOption('mainGroup').items;

            // SAUVEGARDE DES OPTIONS DU SWITCH DE RECURRENCE
            var recurrenceSwitchOptions;
            mainGroupItems.map((i) => {
                if (i.items) {
                    i.items.map((j) => {
                        if (j.dataField === "repeat" && j.editorOptions) {
                            recurrenceSwitchOptions = j.editorOptions;
                        }
                        return j;
                    });
                }
                return i;
            });

            // CONSTRUCTION DU NOUVEAU FORM PRINCIPAL
            form.itemOption('mainGroup', "items", [{
                colSpan: 2,
                label: {
                    text: "Offre"
                },
                dataField: "text",
                editorType: "dxTextBox",
                editorOptions: {
                    value: this.offre.titre,
                    readOnly: true
                }
            }, {
                colCountByScreen: {
                    lg: 2,
                    xs: 1
                },
                colSpan: 2,
                itemType: "group",
                items: [
                    {
                        colSpan: 1,
                        label: {
                            text: "Date de début"
                        },
                        dataField: "startDate",
                        editorType: "dxDateBox",
                        editorOptions: {
                            value: startDate,
                            type: "datetime",
                            width: "100%",
                            min: this.minDate.getTime(),
                            max: this.maxDate.getTime(),
                            onValueChanged: (args) => {
                                startDate = new Date(args.value);

                                // DATE FIN RECALCULEE A DATE DEBUT +dureeMN
                                form.updateData("endDate", new Date(startDate.getTime() + 1000 * 60 * this.duree));
                            }
                        },
                        validationRules: [
                            {
                                type: "required"
                            }
                        ]
                    }, {
                        label: {
                            text: "Date de fin"
                        },
                        name: "endDate",
                        dataField: "endDate",
                        editorType: "dxDateBox",
                        editorOptions: {
                            // DATE FIN PAR DEFAUT A DATE DEBUT +dureeMN
                            value: new Date(startDate.getTime() + 1000 * 60 * this.duree),
                            width: "100%",
                            type: "datetime",
                            readOnly: true
                        }
                    }
                ],
            }, {
                colCountByScreen: {
                    lg: 3,
                    xs: 3
                },
                colSpan: 2,
                itemType: "group",
                items: [
                    {
                        cssClass: "dx-appointment-form-switch",
                        name: "visibilityChanged",
                        dataField: "repeat",
                        editorType: "dxSwitch",
                        label: {
                            text: "Récurrence",
                            location: "right"
                        },
                        // ON RE-APPLIQUE LES OPTIONS DU SWITCH DE RECURRENCE
                        editorOptions: recurrenceSwitchOptions
                    }
                ],
            }]);

            // MODIFICATION DU FORM DE RECURRENCE
            form.itemOption('recurrenceGroup').items[0].editorOptions.onContentReady = element => {
                function _interopRequireDefault(obj) {
                    return obj && obj.__esModule ? obj : {
                        "default": obj
                    }
                }

                function _getPrototypeOf(o) {
                    _getPrototypeOf = Object.setPrototypeOf ? Object.getPrototypeOf : function(o) {
                        return o.__proto__ || Object.getPrototypeOf(o)
                    };
                    return _getPrototypeOf(o)
                }

                function _get(target, property, receiver) {
                    if ("undefined" !== typeof Reflect && Reflect.get) {
                        _get = Reflect.get
                    } else {
                        _get = function(target, property, receiver) {
                            var base = _superPropBase(target, property);
                            if (!base) {
                                return
                            }
                            var desc = Object.getOwnPropertyDescriptor(base, property);
                            if (desc.get) {
                                return desc.get.call(receiver)
                            }
                            return desc.value
                        }
                    }
                    return _get(target, property, receiver || target)
                }

                function _superPropBase(object, property) {
                    while (!Object.prototype.hasOwnProperty.call(object, property)) {
                        object = _getPrototypeOf(object);
                        if (null === object) {
                            break
                        }
                    }
                    return object
                }

                var component = element.component.instance();

                component.__proto__._createRepeatEndEditor = function() {
                    var _this6 = this;
                    var repeatType = this._recurrenceRule.getRepeatEndRule();
                    var REPEAT_END_TYPE_EDITOR = "dx-recurrence-radiogroup-repeat-type";
                    return [{
                        dataField: "repeatEnd",
                        editorType: "dxRadioGroup",
                        editorOptions: {
                            items: [{
                                type: 'until'
                            }, {
                                type: 'count'
                            }],
                            value: repeatType == 'never' ? 'until' : repeatType,
                            valueExpr: "type",
                            field: "repeatEnd",
                            itemTemplate: function(itemData) {
                                if ("count" === itemData.type) {
                                    return _this6._renderRepeatCountEditor()
                                }
                                if ("until" === itemData.type) {
                                    return _this6._renderRepeatUntilEditor()
                                }
                                return _this6._renderDefaultRepeatEnd()
                            },
                            layout: "vertical",
                            elementAttr: {
                                "class": REPEAT_END_TYPE_EDITOR
                            },
                            onValueChanged: function(args) {
                                return _this6._repeatEndValueChangedHandler(args)
                            }
                        },
                        label: {
                            text: "Fin récurrence"
                        }
                    }]
                }

                component.__proto__._renderRepeatUntilEditor = function() {
                    var REPEAT_END_EDITOR = "dx-recurrence-repeat-end"
                    var WRAPPER_POSTFIX = "-wrapper"
                    var LABEL_POSTFIX = "-label"
                    var REPEAT_UNTIL_DATE_EDITOR = "dx-recurrence-datebox-until-date"
                    var _date_box = _interopRequireDefault(require("devextreme/ui/date_box"))
                    var _renderer = _interopRequireDefault(require("devextreme/core/renderer"))
                    // SELECTIONNER PAR DEFAUT FIN RECURRENCE A DATE CHOISIE + 30 JOURS
                    this._recurrenceRule.getRules().until = this._formatUntilDate(new Date(startDate.getTime() + 1000 * 60 * 60 * 24 * 30))
                    var repeatUntil = this._recurrenceRule.getRules().until || this._formatUntilDate(new Date);
                    var $editorWrapper = (0, _renderer.default)("<div>").addClass(REPEAT_END_EDITOR + WRAPPER_POSTFIX);
                    (0, _renderer.default)("<div>").text("Jusqu'au").addClass(REPEAT_END_EDITOR + LABEL_POSTFIX).appendTo($editorWrapper);
                    this._$repeatDateEditor = (0, _renderer.default)("<div>").addClass(REPEAT_UNTIL_DATE_EDITOR).appendTo($editorWrapper);
                    this._repeatUntilDate = this._createComponent(this._$repeatDateEditor, _date_box.default, {
                        field: "until",
                        value: repeatUntil,
                        type: "date",
                        onValueChanged: this._repeatUntilValueChangeHandler.bind(this),
                        calendarOptions: {
                            firstDayOfWeek: this._getFirstDayOfWeek(),
                            min: startDate.getTime(),
                            // MAX 1 AN
                            max: (new Date()).getTime() + 1000 * 60 * 60 * 24 * 365
                        }
                    });
                    return $editorWrapper
                }

                component.__proto__._optionChanged = function(args) {
                    var _type = require("devextreme/core/utils/type");
                    var _date2 = _interopRequireDefault(require("../../node_modules/devextreme/localization/date"));
                    switch (args.name) {
                        case "value":
                            this._recurrenceRule.makeRules(args.value);
                            this._changeRepeatIntervalLabel();
                            this._disableRepeatEndParts();
                            // this._changeEditorsValue(this._recurrenceRule.getRules());
                            _get(_getPrototypeOf(RecurrenceEditor.prototype), "_optionChanged", this).call(this, args);
                            break;
                        case "startDate":
                            this._makeRepeatOnRule(this._recurrenceRule.getRules().freq);
                            if ((0, _type.isDefined)(this._recurrenceRule.getRecurrenceString())) {
                                this._changeEditorValue()
                            }
                            break;
                        case "firstDayOfWeek":
                            if (this._weekEditor) {
                                var localDaysNames = _date2.default.getDayNames("abbreviated");
                                var dayNames = days.slice(args.value).concat(days.slice(0, args.value));
                                var itemsButtonGroup = localDaysNames.slice(args.value).concat(localDaysNames.slice(0, args.value)).map(function(item, index) {
                                    return {
                                        text: item,
                                        key: dayNames[index]
                                    }
                                });
                                this._weekEditor.option("items", itemsButtonGroup)
                            }
                            if (this._$repeatDateEditor) {
                                this._repeatUntilDate.option("calendarOptions.firstDayOfWeek", this._getFirstDayOfWeek())
                            }
                            break;
                        default:
                            _get(_getPrototypeOf(RecurrenceEditor.prototype), "_optionChanged", this).call(this, args)
                    }
                }
            };
            // form.itemOption('recurrenceGroup').items[0].editorOptions.value = 'FREQ\=DAILY;UNTIL=' + (new Date(startDate.getTime() + 1000 * 60 * 60 * 24 * 30)).toISOString().replace(/[-:]/g, "").replace(".000", "");
        },
        onAppointmentAdded(e) {
            this.saveSchedulerDates(e.appointmentData);
        },
        onAppointmentUpdated(e) {
            this.saveSchedulerDates(e.appointmentData);
        },
        onAppointmentDeleted(e) {
            if (e.appointmentData.id) {
                this.deleteSchedulerDate(e.appointmentData);
            }
        },
        saveSchedulerDates(appointmentData) {
            var appointmentDates = [];
            var dtstart = this.toUTC(new Date(appointmentData.startDate));
            if (appointmentData.recurrenceRule) {
                var rule = rrulestr(appointmentData.recurrenceRule);
                rule.options.dtstart = dtstart;
                appointmentDates = rule.all().map(d => {
                    d.setHours(dtstart.getHours(), dtstart.getMinutes(), 0);

                    return moment(d).format("YYYY-MM-DD HH:mm:00");
                });
            } else {
                appointmentDates = [
                    moment(dtstart).format("YYYY-MM-DD HH:mm:00")
                ];
            }
            this.$emit('save', appointmentDates);

            if (this.saveUrl) {
                axios
                    .post(
                        this.saveUrl,
                        JSON.stringify(appointmentDates)
                    )
                    .then((resp) => {
                        console.log(resp);
                    })
                    .catch((error) => {
                        console.log('ERROR', error);
                    });
            }
        },
        deleteSchedulerDate(appointmentData) {
            this.$emit('delete', appointmentData);

            if (this.deleteUrl) {
                axios
                    .delete(
                        this.deleteUrl,
                        {
                            data: {
                                id: appointmentData.id
                            }
                        }
                    )
                    .then((resp) => {
                        console.log(resp);
                    })
                    .catch((error) => {
                        console.log("ERROR", error);
                    });
            }
        },
        toUTC(date) {
            return new Date(Date.UTC(
                date.getUTCFullYear(),
                date.getUTCMonth(),
                date.getUTCDate(),
                date.getUTCHours(),
                date.getUTCMinutes(),
                0
            ));
        },
    },
};
</script>

<style scoped>
@import "https://cdn3.devexpress.com/jslib/20.2.5/css/dx.common.css";
@import "https://cdn3.devexpress.com/jslib/20.2.5/css/dx.light.css";
@-moz-document url-prefix() {
    .dx-scheduler-work-space-month .dx-scheduler-date-table-cell {
        position: relative;
    }
    .dx-scheduler-work-space-month .dx-scheduler-date-table-cell .disable-date {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}
.disable-date {
    height: 100%;
}
.disable-date {
    background-image: repeating-linear-gradient(135deg,
        rgba(244, 67, 54, 0.1),
        rgba(244, 67, 54, 0.1) 4px,
        transparent 4px,
        transparent 9px);
    color: #9B6467;
}
.dx-scheduler-header-panel-cell .disable-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
